import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Create, organize, and apply 3D models for seamless offsite product integration.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Projects</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Project Home</AnchorLink>
  <AnchorLink mdxType="AnchorLink">File Manager</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Element Sets</AnchorLink>
  <AnchorLink mdxType="AnchorLink">File Version Control</AnchorLink>
    </AnchorLinks>
    <h2>{`Projects`}</h2>
    <p>{`To truly bring offsite products into design, we have our Project environment which allows you a space to manage 3D models ready for those products to be instantly applied.`}</p>
    <p>{`To begin, you can make a `}<inlineCode parentName="p">{`New Project`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/13_projects-new-project.png",
      "alt": "13_projects-new-project.png"
    }}></img>
    <p>{`From here you can enter simple information including where in the world it is. This will help us later on when we surface suitable products for you.`}</p>
    <p>{`Now you will have access to everything relating to that project. This is also where you will access the Project sidebar including quick access to all the tools you need. Let’s walk through each;`}</p>
    <h2>{`Project Home`}</h2>
    <p>{`Your Project Home includes the information you have entered, plus links to areas where you can manage your inputs and outputs as well as utilize the KOPE configurator where you can test offsite products directly in the project.`}</p>
    <p>{`This is a catch-all area to get an overview of the project information.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/14_projects-project-home.png",
      "alt": "14_projects-project-home.png"
    }}></img>
    <h2>{`File Manager`}</h2>
    <p>{`This is where you upload and manage files relating to your project such as 3D models to test products on, drawings for use by your team as well as secondary items such as PDFs and images.`}</p>
    <p>{`To truly benefit from KOPE, you should upload your own project design files in IFC format which you can use later. The File Manager area allows you to get a full breakdown of files you have available including the ability to filter through and search as your project becomes more complex.`}</p>
    <p>{`You can also add new files directly from this screen.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/15_file-manager.png",
      "alt": "15_file-manager.png"
    }}></img>
    <h2>{`Element Sets`}</h2>
    <p>{`If your file is IFC, you can go a level deeper and create ‘Element Sets’. These are groupings of content within your model that you can apply products to directly. For example, you want to apply different products to walls within your building that change based on performance requirements.`}</p>
    <p>{`You can choose a parameter from the IFC file, filter those down further and then group them.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/16_projects-element-sets.png",
      "alt": "16_projects-element-sets.png"
    }}></img>
    <p>{`As KOPE uploads and processes your file you will see a `}<strong parentName="p">{`Pending`}</strong>{` status on the file list. When you see `}<strong parentName="p">{`Completed`}</strong>{` , you are ready to apply products.`}</p>
    <h2>{`File Version Control`}</h2>
    <p>{`Version tracking is automatic within KOPE. To use this effectively, previously existing files and the new file must have the same name. All previously uploaded versions of a file can be viewed and downloaded from the KOPE Files Manager.`}</p>
    <p>{`To view a previous version, stay in the File Manager and click on the `}<inlineCode parentName="p">{`⫶`}</inlineCode>{` icon alongside your desired file. Choose `}<inlineCode parentName="p">{`Preview File`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/17_file-version-control-previewing-fIles.png",
      "alt": "17_file-version-control-previewing-fIles.png"
    }}></img>
    <p>{`With the file now previewing, you can explore all previous versions uploaded to KOPE with ease.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/18_file-version-control-preview-versions.png",
      "alt": "18_file-version-control-preview-versions.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      